<template>
  <nut-navbar @on-click-back="returnClick()" title="绑定手机号"></nut-navbar>
  <div class="content">
    <div class="blank10"></div>
    <div class="normal-formbox newform">
      <form :model="phoneForm">
        <nut-row>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机号"
                @change="change"
                @blur="userBlur"
                v-model="phoneForm.phone"
                @clear="clear"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入验证码"
                @change="change"
                v-model="phoneForm.code"
                @clear="clear"
            />
            <div class="piccode" @click="getGraphicVerificationCode(true)">
              <img
                  id="picCode"
                  style="height: 30px; width: 80px; cursor: pointer"
                  :src="phoneForm.imgSrc"
              />
            </div>
          </nut-col>

          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机验证码"
                @change="change"
                v-model="phoneForm.phoneCode"
                @clear="clear"
            />
            <div id="getPhoneCodeDiv" :style="phoneForm.phone == '' ? style : ''" class="piccode">
              <nut-button plain id="getPhoneCode" type="info" size="small" @click="getPhoneCode(true)"
                          :disabled="phoneForm.phone == '' ?isDisabled : false">发送验证码
              </nut-button>
            </div>
          </nut-col>
          <nut-col :span="24" class="form-item lg-btn">
            <nut-button plain type="info" class="blue-btn" @click="setPhone()"
                        :loading="isLoading">{{ confirmContent }}
            </nut-button>
          </nut-col>

        </nut-row>
      </form>
    </div>
  </div>
</template>
<script>
import {getCurrentInstance, onMounted, reactive, ref, toRefs} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import axios from "axios";
import {Notify} from "@nutui/nutui";

export default {
  name: 'bindPhone',
  setup() {
    const {proxy} = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    //Base64 加密解密
    let Base64 = require('js-base64').Base64;
    //确认按钮文本内容
    let confirmContent = ref("确认");
    //按钮不进入加载状态，可点击
    let isLoading = ref(false);
    //发送验证码按钮 可点击
    const isDisabled = ref(true);
    //发送验证码div
    const style = {'pointer-events': 'none'};
    let onLineId = '';
    let innerId = route.query.innerId !== undefined ? route.query.innerId : '';
    let etpId = '';
    //组件加载后，执行
    onMounted(
        () => {
          getGraphicVerificationCode();
          timer();
          onLineId = route.query.onLineId !== undefined ? route.query.onLineId : '';
          if (onLineId == null || onLineId === '') {
            Notify.error('绑定异常，请重新登录');
            router.push("/phoneWeb/")
          }
        }
    )
    const clear = (value) => {
      console.log('clear:', value);
    };
    const change = (value, event) => {
      console.log('change: ', value, event)
    }
    //返回登录页面
    const returnClick = () => {
      router.push("/phoneWeb/")
    }
    const phoneForm = reactive({
      phone: '',
      code: '',
      imgSrc: '',
      codeAccount: '',
      phoneCode: ''
    })
    //获取图形验证码
    const getGraphicVerificationCode = () => {
      const url = "https://service.sview3d.com/service/user/kaptcha";
      axios.post(url).then(
          function (res) {
            phoneForm.imgSrc = 'data:image/jpg;base64,' + res.data.img;
            phoneForm.codeAccount = res.data.uuid;
          }
      ).catch(res => {
            console.log("失败");
          }
      )
    }
    // 手机验证码
    const getPhoneCode = () => {
      const url = 'https://service.sview3d.com/service/user/getVerificationCode';
      const params = new URLSearchParams();
      params.append('phone', phoneForm.phone);
      params.append('code', phoneForm.code);
      params.append('account', phoneForm.codeAccount);
      //校验手机号是否正确
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(phoneForm.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }
      axios.post(url, params).then(
          function (res) {
            if (res.data.return === "0" || res.data.return === 0) {
              Notify.success("验证码已发送");
            } else {
              Notify.warn(res.data.msg);
              getGraphicVerificationCode();
            }
          }
      ).catch(res => {
            console.log("失败");
          }
      )
    }
    const timer = () => {
      const getPhoneCodeDiv = document.getElementById('getPhoneCodeDiv');
      const getPhoneCode = document.getElementById('getPhoneCode');
      var timer = null;
      getPhoneCode.addEventListener('click', function () {
        //style="pointer-events: none"
        getPhoneCodeDiv.style.pointerEvents = 'none';
        isDisabled.value = true;
        let num = 60;
        getPhoneCode.innerHTML = num + 's后重新发送';
        timer = setInterval(function () {
          num--;
          getPhoneCode.innerHTML = num + 's后重新发送';
          if (num == 0) {
            clearInterval(timer);
            getPhoneCodeDiv.style.pointerEvents = '';
            isDisabled.value = false;
            getPhoneCode.innerHTML = '发送验证码';
          }
        }, 1000)
      })
    }
    //绑定手机号
    const setPhone = () => {
      //校验参数
      if (phoneForm.phone == null || phoneForm.phone === '') {
        Notify.warn('手机号不能为空!');
        return false;
      }
      //校验手机号是否正确
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(phoneForm.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }
      if (phoneForm.phoneCode == null || phoneForm.phoneCode === '') {
        Notify.warn('手机验证码不能为空!');
        return false;
      }
      isLoading.value = true;
      confirmContent.value = "绑定中";
      const urlOne = "https://work.sview3d.com/bindPhone";
      const paramsOne = new URLSearchParams();
      paramsOne.append("online_id", Base64.decode(onLineId));
      paramsOne.append("phone", phoneForm.phone);
      paramsOne.append("verify_code", phoneForm.phoneCode);
      axios.post(urlOne, paramsOne).then(function (res) {
        console.log(res.data)
        const data = res.data;
        if (data.return === '0' || data.return === 0) {
          //绑定成功，跳转页面
          jump(innerId)
        } else if (data.return === '1' || data.return === 1) {
          //手机号已存在，合并账号
          proxy.$dialog({
            title: '是否合并',
            content: data.msg,
            okText: "确认",
            cancelText: "取消",
            onOk: function () {
              accountMerger(data.userId, data.phoneUserId)
            }
          });
        } else {
          //错误
          proxy.$dialog({
            title: '错误提示',
            content: data.msg,
            noCancelBtn: true
          });
        }
        isLoading.value = false;
        confirmContent.value = "确认";
      }).catch(function (error) {
        proxy.$dialog({
          title: '错误提示',
          content: error,
          noCancelBtn: true
        });
        isLoading.value = false;
        confirmContent.value = "确认";
      })
    }
    //账号合并
    const accountMerger = (userId, phoneUserId) => {
      const urlOne = "https://service.sview3d.com/service/user/bind";
      const paramsOne = new URLSearchParams();
      paramsOne.append("user_id", phoneUserId);
      paramsOne.append("other_user_id", userId);
      axios.post(urlOne, paramsOne).then(function (res) {
        console.log(res.data)
        let data = res.data;
        if (data.return === '0' || data.return === 0) {
          jump(Base64.encode(phoneUserId))
        } else {
          //错误
          proxy.$dialog({
            title: '错误提示',
            content: data.msg,
            noCancelBtn: true
          });
        }
        isLoading.value = false;
        confirmContent.value = "确认";
      }).catch(function (error) {
        proxy.$dialog({
          title: '错误提示',
          content: error,
          noCancelBtn: true
        });
        isLoading.value = false;
        confirmContent.value = "确认";
      })
    }
    const userBlur = (value, event) => {
      //2、查询账号所属公司
      const etpUrl = "https://work.sview3d.com/phone/getEnterpriseAdmin";
      const etpParams = new URLSearchParams();
      etpParams.append('phoneOrEmail', value);
      proxy.$axios.post(etpUrl, etpParams).then(function (response) {
        console.log("-----------查询账号所属公司-------------")
        console.log(response)
        console.log("-----------查询账号所属公司 end-------------")
        let data1 = response.data;
        if (data1.success) {
          etpId = data1.innerId;
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
    //跳转页面
    const jump = (value) => {
      if (null == etpId || '' === etpId) {
        //跳转企业登记页面
        router.push({
          path: '/phoneWeb/CorporateInfoSet',
          query: {
            innerId: value,
            phone: phoneForm.phone
          }
        });
      } else {
        //跳转到成员管理页面
        router.push({
          path: '/phoneWeb/member',
          query: {
            innerId: value
          }
        });
        Notify.success('欢迎登录', {
          duration: 1500
        });
      }
      Notify.success('欢迎登录', {
        duration: 1500
      });
    }

    return {
      change,
      clear,
      phoneForm,
      returnClick,
      getPhoneCode,
      getGraphicVerificationCode,
      isDisabled,
      timer,
      confirmContent,
      isLoading,
      setPhone,
      style,
      userBlur,
    }
  }
}
</script>